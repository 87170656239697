
import { isEmpty } from "lodash";
import Vue from "vue";
const initialData = {
  stem: "",
  analysis: "",
  answerOptionData: [
    {
      value: "A",
      label: "",
      delPower: false,
    },
    {
      value: "B",
      label: "",
      delPower: false,
    },
    {
      value: "C",
      label: "",
      delPower: false,
    },
    {
      value: "D",
      label: "",
      delPower: false,
    },
  ],
  answer: ["A"],
  questionType: 2,
  judgmentAnswer: true,
};
export default Vue.extend({
  props: {
    visible: { type: Boolean },
    data: { type: Object },
  },
  data() {
    return {
      isShowAddBtn: true,
      hasEditItem: false,
      loading: false,
      radio: 1,
      questionData: {
        ...initialData,
      },
    };
  },
  computed: {
    judgementAnswer(): boolean {
      return this.questionData.answer?.[0] === "judgementTrue";
    },
  },
  watch: {
    visible: {
      handler(val) {
        if (val) {
          if (!isEmpty(this.data)) {
            this.questionData.stem = this.data.name;
            this.questionData.questionType = this.data.classType;
            this.$set(
              this.questionData,
              "answer",
              JSON.parse(this.data.standAnswerArray)
            );
            this.$set(
              this.questionData,
              "answerOptionData",
              JSON.parse(this.data.viewSettings)
            );
            this.questionData.analysis = this.data.remark;
          } else {
            this.questionData.stem = "";
            this.questionData.questionType = 1;
            this.$set(this.questionData, "answer", []);
            this.$set(this.questionData, "answerOptionData", [
              {
                value: "A",
                label: "",
                delPower: false,
              },
              {
                value: "B",
                label: "",
                delPower: false,
              },
              {
                value: "C",
                label: "",
                delPower: false,
              },
              {
                value: "D",
                label: "",
                delPower: false,
              },
            ]);
            this.questionData.analysis = "";
          }
        }
      },
    },
  },
  methods: {
    addItem(i) {
      this.questionData.answerOptionData.push({
        label: "",
        value: String.fromCharCode(i + 65),
        delPower: true,
      });
      if (i >= 26) {
        this.isShowAddBtn = false;
        return;
      }
    },
    removeItem(i) {
      if (this.questionData.answerOptionData.length === i + 1) {
        this.questionData.answerOptionData.splice(i, 1);
        return;
      } else {
        this.questionData.answerOptionData.splice(i, 1);
        const length = this.questionData.answerOptionData.length;
        for (let index = i; index < length; index++) {
          // const element = array[i];
          this.questionData.answerOptionData[index].value = String.fromCharCode(
            index + 65
          );
        }
      }
    },
    handleJudgementSelect(value) {
      this.questionData.answer.splice(0, this.questionData.answer.length);
      this.questionData.answer.push(value ? "judgementTrue" : "judgementFalse");
    },
    handleQuestionTypeSelect(e) {
      this.$set(this.questionData, "answer", []);
    },
    chooseAnswer(value) {
      if (this.questionData.questionType === 1) {
        this.questionData.answer = [value];
      } else if (this.questionData.questionType === 2) {
        if (this.questionData.answer.includes(value)) {
          this.questionData.answer.splice(
            this.questionData.answer.findIndex((v) => v === value),
            1
          );
        } else {
          this.questionData.answer.push(value);
        }
      }
    },
    closeModal() {
      this.$emit("update:visible", false);
    },
    notEmpty() {
      const {
        stem,
        analysis,
        answerOptionData,
        answer,
        questionType,
        judgmentAnswer,
      } = this.questionData;
      if (!stem) {
        this.$message.warning("请输入题干");
        return;
      }
      // if (!analysis) {
      //   this.$message.warning('请输入解析')
      //   return
      // }
      if (
        questionType !== 3 &&
        !answerOptionData.every((v) => v.label && v.value)
      ) {
        this.$message.warning("请输入选项");
        return;
      }
      if (questionType !== 3 && !answer.length) {
        this.$message.warning("请选择答案");
        return;
      }
      if (questionType === 3 && judgmentAnswer === undefined) {
        this.$message.warning("请选择答案");
        return;
      }
      return true;
    },
    handleConfirm() {
      if (this.notEmpty()) {
        const temp = this.questionData.answerOptionData.map((item) => {
          return {
            label: item.label,
            value: item.value,
          };
        });
        const payload = {
          classType: this.questionData.questionType,
          name: this.questionData.stem,
          viewSettings: JSON.stringify(temp),
          standAnswerArray: JSON.stringify(this.questionData.answer),
          remark: this.questionData.analysis,
          id: this.data?.id,
        };
        this.$store.dispatch("questionBankModule/createEditQuestion", payload);
        this.closeModal();
      }
    },
  },
});
