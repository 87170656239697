
import Vue from 'vue'
import QuestionItem from './QuestionItem.vue'

export default Vue.extend({
  components: { QuestionItem },
  computed: {
    questionList(): any {
      return this.$store.getters['questionBankModule/filteredQuestionList']
    },
  },
})
