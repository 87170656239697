
import Vue from 'vue'
import LeftRightLayout from '@/layout/left-right-layout/left-right-layout.vue'
import TopBotLayout from '@/layout/top-bot-layout/top-bot-layout.vue'
import CatalogueHeader from '@/components/catalogue/catalogue-header.vue'
import CatalogueItem from '@/components/catalogue/catalogue-item.vue'
import CreateCatalogueModal from '@/components/catalogue/create-catalogue-modal.vue'
import ToolHeader from '@/features/QuestionBank/ToolHeader.vue'
import QuestionWrapper from '@/features/QuestionBank/QuestionWrapper.vue'

export default Vue.extend({
  components: {
    LeftRightLayout,
    TopBotLayout,
    CatalogueHeader,
    CatalogueItem,
    CreateCatalogueModal,
    ToolHeader,
    QuestionWrapper,
  },
  data() {
    return {
      createCatalogueModalVisible: false,
    }
  },
  computed: {
    catalogueList(): any[] {
      return this.sortList(this.$store.state.questionBankModule.catalogueList)
    },
    editCatalogueName() {
      return this.$store.getters['questionBankModule/editCatalogueName']
    },
  },
  created() {
    this.$store.commit('breadcrumbList', [
      {
        name: '题库管理',
        path: 'questionBank',
      },
    ])
    this.$store.dispatch('questionBankModule/init')
  },
  methods: {
    sortList(list: any[]) {
      return list.sort((a, b) => {
        if (a.createNickName === '超级管理员')
          return -1

        return 0
      })
    },
    createCatalogue() {
      this.$store.commit('questionBankModule/setEditCatalogue', {})
      this.createCatalogueModalVisible = true
    },
    isSelected(id) {
      return id === this.$store.getters['questionBankModule/selectedCatalogueId']
    },
    isCreateBySelfOrAdmin(createUserId) {
      return createUserId === this.$store.state.userInfo.userId || this.$store.getters.isAdmin
    },
    selectCatalogue(item) {
      this.$store.commit('questionBankModule/setSelectedCatalogue', item)
      this.$store.dispatch('questionBankModule/getQuestionsByCatalogueId', {
        catalogeId: item.id,
      })
    },
    editCatalogue(item) {
      this.$store.commit('questionBankModule/setEditCatalogue', item)
      this.createCatalogueModalVisible = true
    },
    deleteCatalogue(item) {
      this.$store.dispatch(
        'questionBankModule/deleteCatalogue',
        { id: item.id },
      )
    },
    createOrEditCatalogueConfirm(inputValue) {
      this.$store.dispatch(
        'questionBankModule/createEditCatalogue',
        {
          name: inputValue,
          id: this.$store.state.questionBankModule.editCatalogue?.id,
        },
      )
    },
  },
})
