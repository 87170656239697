
import Vue from "vue";
import CreateEditQuestionModal from "./CreateQuestionModal.vue";
import type { PropType } from "vue/types/v3-component-props";
enum QuestionType {
  "单选题" = 1,
  "多选题" = 2,
  "判断题" = 3,
}
export default Vue.extend({
  components: { CreateEditQuestionModal },
  // props: ["data", "showCheckBox", "selected", "noEdit"],
  props: {
    data: {
      type: Object as PropType<any>,
      required: true,
    },
    showCheckBox: {
      type: Boolean,
      default: true,
      required: true,
    },
    selected: {
      type: Boolean,
      default: true,
      required: true,
    },
    noEdit: {
      type: Boolean,
      default: true,
      required: true,
    },
    sortIndex: {
      type: Number,
      default: 0,
      required: false,
    },
  },
  data() {
    return {
      visible: false,
      editData: {},
    };
  },
  computed: {
    showSortIndex(): boolean {
      return this.sortIndex !== 0;
    },
    questionType(): string {
      return QuestionType[this.data.classType];
    },
    stem(): string {
      return this.data.name;
    },
    answer(): string {
      return this.formatAnswer(this.data.standAnswerArray);
    },
    options(): string {
      return this.formatOptions(this.data.viewSettings);
    },
    isJudgementQuestion(): boolean {
      return this.data.classType === QuestionType["判断题"];
    },
    remark(): string {
      return this.data.remark;
    },
  },
  methods: {
    // [{"value":"A","label":"13"},{"value":"B","label":"321"},{"value":"C","label":"3212"},{"value":"D","label":"12"}]
    formatOptions(raw) {
      if (this.data.classType !== QuestionType["判断题"])
        return JSON.parse(raw);

      return [];
    },
    formatAnswer(raw) {
      return JSON.parse(raw);
    },
    handleEditQuestion() {
      this.visible = true;
      this.editData = this.data;
    },
    deleteQuestion() {
      this.$store.dispatch("questionBankModule/deleteQuestion", {
        id: this.data.id,
      });
    },
    handleChange() {
      this.$emit("handleChange", this.data.id);
    },
  },
});
