
import Vue from 'vue'
import CreateQuestionModal from './CreateQuestionModal.vue'
import SearchInput from '@/components/SearchInput.vue'

export default Vue.extend({
  components: { SearchInput, CreateQuestionModal },
  data() {
    return {
      category: '全部',
      categoryOptions: [
        {
          value: 0,
          label: '全部',
        },
        {
          value: 1,
          label: '单选题',
        },
        {
          value: 2,
          label: '多选题',
        },
        {
          value: 3,
          label: '判断题',
        },
      ],
      createQuestionVisible: false,
      selectCategory: undefined,
      hasSelectedCatalogue: true,
    }
  },
  computed: {},
  methods: {
    searchInputChange(value) {
      console.log(value)
      this.$store.commit('questionBankModule/setSearchText', value)
    },
    createResourceClick() {
      this.createQuestionVisible = true
    },
    selectCategoryChange(value) {
      this.selectCategory = value
      this.$store.commit('questionBankModule/setSelectQuestionType', value)
    },
  },
})
